import React from "react";

const Promotions = () => {
  return (
    <div className="flex justify-center items-center my-20 font-semibold text-4xl">
      Comming Soon!!
    </div>
  );
};

export default Promotions;
